var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"Dealer"}},[_c('div',{staticClass:"map_toolbox"},[_c('div',{staticClass:"searchbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_keyword),expression:"search_keyword"}],staticClass:"sb__field",attrs:{"placeholder":"尋找 MJP經銷門市","type":"text"},domProps:{"value":(_vm.search_keyword)},on:{"focus":function($event){_vm.show_seatch_list = true},"input":function($event){if($event.target.composing){ return; }_vm.search_keyword=$event.target.value}}}),_c('button',{staticClass:"clear_btn",class:{ active: _vm.search_keyword.length != 0 },on:{"click":_vm.ClearKeyWord}}),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.search_keyword.length != 0 && _vm.show_seatch_list),expression:"search_keyword.length != 0 && show_seatch_list"}],staticClass:"search_list"},_vm._l((_vm.shop_list.filter(function (shop) {
            return (
              shop.address.indexOf(_vm.search_keyword) != -1 ||
              shop.name.indexOf(_vm.search_keyword) != -1
            );
          })),function(item){return _c('li',{key:item.shop_id,on:{"click":function($event){return _vm.ChooseShop(item.shop_id)}}},[_vm._v(" "+_vm._s(item.name)+" "),_c('span',[_vm._v(_vm._s(item.address))])])}),0)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.search_box_show),expression:"search_box_show"}],staticClass:"shop_detailbox"},[(_vm.search_shop_id != -1)?[_c('div',{staticClass:"title_box"},[_c('h3',[_vm._v(" "+_vm._s(_vm.shop_list.filter(function (shop) { return shop.shop_id == _vm.search_shop_id; })[0].name)+" ")]),_c('a',{staticClass:"more_btn link_mark",attrs:{"target":"_blank","href":("https://www.google.com/maps/search/?api=1&query=" + (_vm.shop_list.filter(function (shop) {
                return shop.shop_id == _vm.search_shop_id;
              })[0].name) + " " + (_vm.shop_list.filter(function (shop) {
                return shop.shop_id == _vm.search_shop_id;
              })[0].address))}},[_vm._m(0)])]),_c('div',{staticClass:"address_box"},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(" location_on ")]),_c('p',[_vm._v(" "+_vm._s(_vm.shop_list.filter(function (shop) { return shop.shop_id == _vm.search_shop_id; })[0].address)+" ")])]),_c('div',{staticClass:"phone_box"},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(" call ")]),_c('p',[_vm._v(" "+_vm._s(_vm.shop_list.filter(function (shop) { return shop.shop_id == _vm.search_shop_id; })[0].phone)+" ")])])]:_vm._e()],2),_c('div',{attrs:{"id":"map"},on:{"click":function($event){_vm.show_seatch_list = false}}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b',[_vm._v("立即前往")])])}]

export { render, staticRenderFns }